import React from "react";
import Layout from "../components/Layout/layout";
import CovidComponent from "../components/Covid/index";

const CovidPage = () => (
	<Layout>
		<CovidComponent language="DE" />
	</Layout>
);

export default CovidPage;
